<template>
  <div class="card market-image-card">
    <div class="card-body">
      <h5 class="card-title">รูปภาพ</h5>
      <div class="mb-2 upload-icon">
        <label class="text-primary d-flex justify-content-between">
          <span>Icon</span>
          <span class="upload-btn text-secondary" @click="selectFile('icon')"><i class="fas fa-upload"></i></span>
        </label>
        <div class="d-flex">
          <div v-if="srcIcon" class="market-icon">
            <img :src="srcIcon">
            <span @click="deleteImage('icon')">
              <i class="far fa-times-circle"></i>
            </span>
          </div>
        </div>
      </div>

      <hr />
      <div class="mb-2 upload-bg">
        <label class="text-primary d-flex justify-content-between">
          <span>Background</span>
          <span class="upload-btn text-secondary" @click="selectFile('bg')"><i class="fas fa-upload"></i></span>
        </label>
        <div class="d-flex">
          <div v-if="srcBg" class="market-bg">
            <img :src="srcBg">
            <span @click="deleteImage('bg')">
              <i class="far fa-times-circle"></i>
            </span>
          </div>
        </div>
      </div>

      <input ref="inputFile" type="file" accept="image/jpeg, image/png, image/gif" style="display: none;" v-on:change="handleFileUpload()">
      <ViewUploadImage :data="data" :file-type="fileType" :file="file" :is-show="isShowModal" @close="hideUploadModal" />
    </div>
  </div>
</template>
<script>
import MarketService from '@/services/marketService'
import ViewUploadImage from './ViewUploadImage'

import Swal from 'sweetalert2'

export default {
  components: {
    ViewUploadImage
  },
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      file: null,
      isShowModal: false,
      fileType: null
    }
  },
  computed: {
    srcIcon() {
      if(this.data) {
        return this.data.imageIcon
      }else{
        return null
      }
    },
    srcBg() {
      if(this.data) {
        return this.data.imageBg
      }else{
        return null
      }
    }
  },
  methods: {
    selectFile(type) {
      this.fileType = type
      this.$refs.inputFile.click()
    },
    handleFileUpload() {
      this.file = this.$refs.inputFile.files[0]
      console.log('file', this.file)
      if(this.file) {
        this.isShowModal = true
      }
    },
    deleteImage(type) {
      console.log('deleteImage!', type)
      Swal.fire({
        title: 'ยืนยันการลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.deleteMarketImage(this.data._id, type)
          .then((response)=>{
            if(response.success) {
              this.$emit('reload')
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'บันทึกเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch(()=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    hideUploadModal(needReload) {
      this.isShowModal = false
      this.fileType = null
      if(needReload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
<style lang="scss">
.market-image-card {
  .upload-btn:hover {
    color: #007bff !important;
    cursor: pointer;
  }

  .market-icon {
    width: 100px;
    border: 1px solid #eee;
    padding: 10px;
    position: relative;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      top: -10px;
      right: -10px;
      display: none;
      cursor: pointer;
    }
  }
  .market-icon:hover {
    span {
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
    }

    span:hover {
      color: #007bff;
    }
  }

  .market-bg {
    width: 300px;
    border: 1px solid #eee;
    padding: 10px;
    position: relative;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      top: -10px;
      right: -10px;
      display: none;
      cursor: pointer;
    }
  }

  .market-bg:hover {
    span {
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
    }

    span:hover {
      color: #007bff;
    }
  }
}
</style>
