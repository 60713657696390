<template>
  <div class="card market-image-card">
    <div class="card-body">
      <div class="box-title d-flex justify-content-between align-items-center mb-3">
        <h5 class="card-title mb-0">ตั้งค่าหวยชุด</h5>
        <button class="btn btn-link btn-sm" @click="toggleSetting">แก้ไข</button>
      </div>

      <div class="row row-status">
        <div class="col-md-4 mb-3">
          <span>ราคา</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-primary">{{price | amountFormat(2, '0.00')}}</span> (บาท/ชุด)
        </div>
      </div>
      <div class="row row-status">
        <div class="col-md-4 mb-3">
          <span>ส่วนลด</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-primary">{{discount | amountFormat(2, '0.00')}}</span> (บาท/ชุด)
        </div>
      </div>
      <div class="row row-status">
        <div class="col-md-4 mb-3">
          <span>ราคาจ่าย</span>
        </div>
        <div class="col-md-8 mb-3">
          <table class="table table-sm table-bordered table-info">
            <thead>
              <tr>
                <th width="50%" class="text-center">ประเภท</th>
                <th width="30%" class="text-center">จ่าย(บาท/ชุด)</th>
                <th width="20%" class="text-center">จำกัด(ชุด)</th>
              </tr>
            </thead>
            <tbody class="bg-light text-dark">
              <tr v-for="bet in prizes" :key="bet.code">
                <td class="text-center">{{ bet.text }}</td>
                <td class="text-right text-success">{{bet.prize | amountFormat(2, '0.00')}}</td>
                <td class="text-center">{{bet.limit}}</td>
              </tr>
              <tr v-if="!prizes.length">
                <td colspan="3" class="text-center">ไม่มีเลขเปิดรับแทง</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <MarketSetModal :is-show="isShowModal" :data="editData" @close="modalClosed" />
  </div>
</template>
<script>
import MarketSetModal from './MarketSetModal'
import { marketTypes } from '@/configs/market.config'

export default {
  name: 'MarketSetCard',
  components: {
    MarketSetModal
  },
  props: ['data'],
  data() {
    return {
      isShowModal: false,
      editData: null
    }
  },
  computed: {
    price() {
      return this.data?.lottoSet?.price || 0
    },
    discount() {
      return this.data?.lottoSet?.discount || 0
    },
    prizes() {
      const marketType = marketTypes.find((t)=>{
        return t.code === this.data?.marketType
      })

      const set = (marketType?.sets || []).find((s)=>{
        return s.set === this.data?.marketSet
      })

      return (set?.openBets || [])
      .filter((bet)=>{
        return this.data?.openBets?.[bet.code]
      })
      .map((bet)=>{
        return {
          ...bet,
          prize: this.data?.lottoSet?.prizes?.[bet.code] || 0,
          limit: this.data?.lottoSet?.limits?.[bet.code] || 0
        }
      })
    }
  },
  methods: {
    toggleSetting() {
      this.isShowModal = true
      this.editData = {
        _id: this.data?._id,
        price: this.price,
        discount: this.discount,
        prizes: this.prizes
      }
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
