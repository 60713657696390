<template>
  <div class="container market-info px-0">
    <MarketTitle :data="data" @reload="loadMarket" />
    <MarketDetailCard :data="data" @reload="loadMarket" />
    <MarketSetCard v-if="isLottoSet" :data="data" @reload="loadMarket" />
    <MarketImageCard :data="data" @reload="loadMarket" />
  </div>
</template>
<script>
import MarketService from '@/services/marketService'

import MarketTitle from './components/MarketTitle'
import MarketDetailCard from './components/MarketDetailCard'
import MarketImageCard from './components/MarketImageCard'
import MarketSetCard from './components/MarketSetCard'

import Swal from 'sweetalert2'

export default {
  name: 'MarketInfo',
  components: {
    MarketTitle,
    MarketDetailCard,
    MarketImageCard,
    MarketSetCard
  },
  data() {
    return {
      data: null
    }
  },
  computed: {
    isLottoSet() {
      return this.data?.marketType === 'lottoset'
    }
  },
  methods: {
    loadMarket() {
      MarketService.getMarket(this.$route.params.marketId).then((response)=>{
        console.log(response)
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'มีปัญหาในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then((res)=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    }
  },
  mounted() {
    this.loadMarket()
    if(this.$store.state.marketGroups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  }
}
</script>
