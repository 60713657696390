<template>
	<b-modal
    id="add-lotto-market-modal"
		ref="add-lotto-market-modal"
		hide-footer
		:title="modalTitle"
		ok-variant="success"
		:ok-title="modalTitle"
		cancel-title="ยกเลิก"
		@show="resetModal"
    @hidden="resetModal"
	>
    <form
      id="add-lotto-market-form"
    	ref="form"
    	@submit.stop.prevent="handleSubmit"
    >
    	<b-form-group
        label="หวย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          v-model="input.marketTitle"
          type="text"
          placeholder="ชื่อหวย"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เรียงลำดับ"
        label-for="input-market-sort"
      >
        <b-form-input
          id="input-market-sort"
          v-model="input.sort"
          type="text"
          placeholder="เรียงลำดับ"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
      	<b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      	<b-button type="submit" variant="primary" size="sm" :disabled="isProcess || !input.marketTitle">{{isProcess ? 'กำลังบันทึก...' : modalTitle}}</b-button>
      </div>
  	</form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'
import { marketTitleInterface } from '@/interfaces/Market'

import Swal from 'sweetalert2'

export default {
	name: 'LottoMarketTitleModal',
	props: {
		isShow: {
			type: Boolean,
			default: false
		},
    data: {
      type: Object,
      default: marketTitleInterface
    }
	},
	data() {
		return {
			input: marketTitleInterface,
			isProcess: false,
      needReload: false
		}
	},
  computed: {
    modalTitle() {
      return this.input._id ? 'แก้ไขชื่อหวย' : 'เพิ่มหวย'
    }
  },
	watch: {
		isShow() {
			if(this.isShow){
				this.showModal()
			}else{
				this.hideModal()
			}
		},
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
	},
	methods: {
		resetModal() {
      this.needReload = false
		},
		showModal() {
      this.$refs['add-lotto-market-modal'].show()
    },
    hideModal() {
      this.$refs['add-lotto-market-modal'].hide()
    },
		handleSubmit() {
      if(this.input._id) {
        this.update()
      }else{
        this.save()
      }
		},
    save() {
      if(this.input.marketTitle) {
        MarketService.saveMarket(this.input)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$router.push({
              name: 'MarketInfo',
              params: {
                marketId: response.data._id
              }
            })
          }else{
            throw new Error()
          }
        })
        .catch(()=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      MarketService.updateMarketTitle(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw new Error()
          }
        })
        .catch(()=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
    }
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
	}
}
</script>
<style lang="scss" scoped>

</style>
