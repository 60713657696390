<template>
  <div class="market-title d-flex mb-1 align-items-center">
    <strong>{{ title }}</strong>
    <button class="btn btn-sm btn-link" @click="isShowTitleModal=true"><i class="fas fa-pencil-alt"></i> แก้ไข</button>
    <LottoMarketTitleModal :data="editData" :is-show="isShowTitleModal" @close="closeModal" />
  </div>
</template>
<script>
import LottoMarketTitleModal from './LottoMarketTitleModal.vue'
import { marketTitleInterface } from '@/interfaces/Market'

export default {
  name: 'MarketTitle',
  components: {
    LottoMarketTitleModal
  },
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      isShowTitleModal: false,
      editData: marketTitleInterface
    }
  },
  watch: {
    data() {
      this.editData = {
        _id: this.data._id,
        marketTitle: this.data.marketTitle,
        sort: this.data.sort
      }
    }
  },
  computed: {
    title() {
      if(this.data) {
        return this.data.marketTitle
      }else{
        return '-'
      }
    }
  },
  methods: {
    closeModal(reload) {
      this.isShowTitleModal=false
      if(reload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.market-title {
  height: 30px;

  // button {
  //   display: none;
  // }
}
.market-title:hover {
  // button {
  //   display: block;
  // }
}
</style>
