<template>
  <b-modal
    ref="market-set-modal"
    hide-footer
    title="ตั้งค่าหวยชุด"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >
    <form
      id="market-set-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ราคา"
      >
        <b-form-input
          type="text"
          v-model="input.price"
          placeholder="ราคาขาย บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ส่วนลด"
      >
        <b-form-input
          type="text"
          v-model="input.discount"
          placeholder="ส่วนลด บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <fieldset class="form-group">
        <legend class="bv-no-focus-ring col-form-label pt-0">ราคาจ่าย</legend>
        <table class="table table-sm table-bordered table-info">
          <thead>
            <tr>
              <th width="50%" class="text-center">ประเภท</th>
              <th width="30%" class="text-center">จ่าย (บาท)</th>
              <th width="20%" class="text-center">จำกัด (ชุด)</th>
            </tr>
          </thead>
          <tbody class="bg-light text-dark">
            <tr v-for="prize in input.prizes" :key="prize.code">
              <td class="text-center">{{prize.text}}</td>
              <td>
                <b-form-input
                  type="text"
                  v-model="prize.prize"
                  size="sm"
                  :number="true"
                  required
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  type="text"
                  v-model="prize.limit"
                  size="sm"
                  :number="true"
                  required
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'

export default {
  name: 'MarketSetModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.data))
    },
    showModal() {
      this.$refs['market-set-modal'].show()
    },
    hideModal() {
      this.$refs['market-set-modal'].hide()
    },
    handleSubmit() {
      const prizes = (this.input.prizes || []).reduce((prizes, input)=>{
        prizes[input.code] = input.prize
        return prizes
      }, {})

      const limits = (this.input.prizes || []).reduce((limits, input)=>{
        limits[input.code] = input.limit
        return limits
      }, {})

      const setData = {
        isAvailable: true,
        price: this.input.price,
        discount: this.input.discount,
        prizes: prizes,
        limits: limits
      }

      this.isProcess = true
      MarketService.saveLottoSet(this.data._id, setData)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
