<template>
  <b-modal
    ref="view-upload-image-modal"
    hide-footer
    title="อัพโหลดรูป"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3" style="min-height: 250px;">
        <img v-if="urlImage" :src="urlImage" style="max-width: 100%;">
      </div>
      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess"><i class="fas fa-upload"></i> {{isProcess ? 'กำลังอัพโหลด...' : 'อัพโหลด'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'

import Swal from 'sweetalert2'

export default {
  name: 'ViewUploadImage',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    },
    file: {
      type: [Object, String, File],
      default: null
    },
    fileType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fileInput: null,
      urlImage: null,
      isProcess: false
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    file() {
      console.log('this.file', this.file)
      this.fileInput = this.file
      this.urlImage = URL.createObjectURL(this.file)
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.urlImage = null
    },
    showModal() {
      this.$refs['view-upload-image-modal'].show()
    },
    hideModal() {
      this.$refs['view-upload-image-modal'].hide()
    },
    handleSubmit() {
      console.log('submit!')
      if(this.file) {
        this.isProcess = true

        let formData = new FormData()
        formData.append('image', this.file)
        formData.append('imageType', this.fileType)

        MarketService.uploadMarketImage(this.data._id, formData)
        .then((response)=>{
          console.log(response)
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'อัพโหลดไฟล์เสร็จเรียบร้อย',
            })
          }else{
            throw new Error()
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: 'ผิดพลาด!',
            text: e ? e.message : 'อัพโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        .finally(()=>{
          this.isProcess = false
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
