<template>
  <b-modal
    ref="market-setting-modal"
    hide-footer
    size="md"
    title="ตั้งค่าหวย"
    @show="setData"
    @hidden="resetModal"
  >
    <form
      id="lotto-setting-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        id="setting-lotto-types"
        label="ประเภทหวย"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-lotto-types"
          v-model="input.marketType"
          :options="typeOptions"
          :aria-describedby="ariaDescribedby"
          name="markets-types"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        v-if="setOptions.length"
        id="setting-lotto-sets"
        label="หวยชุด"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-lotto-sets"
          v-model="input.marketSet"
          :options="setOptions"
          :aria-describedby="ariaDescribedby"
          name="markets-sets"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        id="setting-lotto-group"
        label="กลุ่มหวย"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-lotto-group"
          v-model="input.groupId"
          :options="groupOptions"
          :aria-describedby="ariaDescribedby"
          name="markets-groups"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="วันที่เปิดแทง"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox-group
          id="markets-open-days"
          v-model="input.openDays"
          :aria-describedby="ariaDescribedby"
          name="markets-open-days"
        >
          <b-form-checkbox v-for="day in daysOfWeek" :key="day.code" :value="day.code">{{ day.text }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <div class="row">
        <div class="col-md-4">
          <b-form-group
            id="lotto-open-time"
            label="เวลาเปิด"
            label-class="text-primary"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-timepicker v-model="input.openTime" locale="en" size="sm" :hour12="false" :no-close-button="true" :minutes-step="5"></b-form-timepicker>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="lotto-close-time"
            label="เวลาปิด"
            label-class="text-primary"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-timepicker v-model="input.closeTime" locale="en" size="sm" :hour12="false" :no-close-button="true" :minutes-step="5"></b-form-timepicker>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="lotto-close-time"
            label="เวลาออกผล"
            label-class="text-primary"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-timepicker v-model="input.announceTime" locale="en" size="sm" :hour12="false" :no-close-button="true" :minutes-step="5"></b-form-timepicker>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        id="lotto-opens"
        label="เลขเปิดรับแทง"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-for="bet in marketOpenBets"
          :key="bet.code"
          v-model="input.openBets[bet.code]"
          :value="true"
          class="custom-control-inline"
        >{{ bet.text }}</b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="ชื่อลิงค์ดูผล"
      >
        <b-form-input
          v-model="input.urlText"
          type="text"
          placeholder="ชื่อลิงค์ดูผล"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ลิงค์ดูผล"
      >
        <b-form-input
          v-model="input.url"
          type="text"
          placeholder="ลิงค์ดูผลหวย"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import MarketService from '@/services/marketService'

import {daysOfWeek, marketOpenBets, marketTypes} from '@/configs/market.config'
import {marketOpenBetsInterface} from '@/interfaces/Market'

import Swal from 'sweetalert2'

export default {
  name: 'MarketSettingModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: {
        groupId: null,
        marketType: 'single',
        marketSet: 'single',
        openDays: [],
        openTime: '00:00:00',
        closeTime: '00:00:00',
        announceTime: '00:00:00',
        openBets: marketOpenBetsInterface,
        urlText: null,
        url: null
      },
      Groups: []
    }
  },
  computed: {
    marketGroups() {
      return this.$store.state.marketGroups
    },
    groupOptions() {
      return this.marketGroups.filter((g)=>{
        return true //g.status === 'Active'
      })
      .map((g)=>{
        return {
          text: g.groupTitle,
          value: g._id
        }
      })
    },
    daysOfWeek() {
      return daysOfWeek
    },
    marketOpenBets() {

      const types = marketTypes.find((t)=>{
        return t.code === this.input.marketType
      })

      if(!types || !types?.sets)
        return marketOpenBets

      const set = types.sets.find((s)=>{
        return s.set === this.input.marketSet
      })

      return set?.openBets || marketOpenBets
    },
    typeOptions() {
      return marketTypes.map((t)=>{
        return {
          text: t.text,
          value: t.code
        }
      })
    },
    setOptions() {
      const types = marketTypes.find((t)=>{
        return t.code === this.input.marketType
      })

      return (types?.sets||[]).map((s)=>{
        return {
          text: s.text,
          value: s.set
        }
      })
    },
    lottoType() {
      return this.input.marketType
    },
    lottoTypeSet() {
      return `${this.input.marketSet}-${this.input.marketType}`
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    // data() {
    //   const data = JSON.parse(JSON.stringify(this.data))
    //   this.input = {
    //     groupId: data.groupId,
    //     marketType: data.marketType || 'single',
    //     marketSet: data.marketSet || 'single',
    //     openDays: data.openDays,
    //     openTime: data.openTime,
    //     closeTime: data.closeTime,
    //     announceTime: data.announceTime,
    //     openBets: {
    //       ...marketOpenBetsInterface,
    //       ...data.openBets
    //     },
    //     urlText: data.urlText,
    //     url: data.url
    //   }
    // },
    lottoTypeSet() {
      const data = JSON.parse(JSON.stringify(this.data))
      if(data.marketType === this.input.marketType && data.marketSet === this.input.marketSet) {
        this.input.openBets = {
          ...JSON.parse(JSON.stringify(marketOpenBetsInterface)),
          ...data.openBets
        }
      }else{
        this.input.openBets = JSON.parse(JSON.stringify(marketOpenBetsInterface))
      }
    },
    lottoType() {
      if(this.input.marketType==='lottoset') {
        console.log('set4')
        this.input.marketSet = 'set4'
      }else{
        this.input.marketSet = 'single'
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['market-setting-modal'].show()
    },
    hideModal() {
      this.$refs['market-setting-modal'].hide()
    },
    setData() {
      const data = JSON.parse(JSON.stringify(this.data))
      this.input = {
        groupId: data.groupId,
        marketType: data.marketType || 'single',
        marketSet: data.marketSet || 'single',
        openDays: data.openDays,
        openTime: data.openTime,
        closeTime: data.closeTime,
        announceTime: data.announceTime,
        openBets: {
          ...JSON.parse(JSON.stringify(marketOpenBetsInterface)),
          ...data.openBets
        },
        urlText: data.urlText,
        url: data.url
      }
    },
    handleSubmit() {
      MarketService.updateMarket(this.data._id, this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
.table-open-bets {
  thead {
    tr {
      th {
        font-size: 90%;
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
